import { useNavigate } from "react-router-dom";
import "./interface.css";
import { useContext, useState } from "react";
import StateContext from "../../context/stateContext";
import axios from "axios";

export const Interface = () => {
  const [results, setResults] = useState("PDF");
  const [file, setFile] = useState("");
  const navigate = useNavigate();
  const {
    show,
    setShow,
    changeHandler,
    handleFrom,
    handleTo,
    handleExcelData,
    handleDepartment,
    selectDepartment,
    csvData,
    dateFrom,
    dateTo,
    emptyDepartment,
    emptyFlag,
    handleExcelDataEmpty,
    departemntsNames,
    handlePdfDataEmpty,
    handleAlldataPdf,
    handleDepartmentPdf,
  } = useContext(StateContext);

  // const departemntsNames = ["3F Incs", "CASS LA", "CASS Newark", "CASS NV", "CASS Oakland ", "CASS PF", "Custom Alloy Trading & Supply, Inc. [CATS]", "Dr. Danielle fratellone  D.C", "Dr. Mark S Ericson DDS, APC", "Dr. Zulfiqar Ali", "Green Peak Insurance ", "Information Technology", "Pyro Minerals Inc.", "Sakra Group ", "Tri-CED Community Recycling", "Weber's Quality Meats, Inc."]

  const generateAllPDF = () => {
    if (csvData) {
      if (dateFrom && dateTo) {
        handleAlldataPdf();
        // navigate("/modified");
      } else {
        alert("no specified date range [from,to]!");
      }
    } else {
      alert("No Selected File!");
    }
  };

  const generateAllExcel = () => {
    if (csvData) {
      if (dateFrom && dateTo) {
        handleExcelData();
      } else {
        alert("no specified date range [from,to]!");
      }
    } else {
      alert("No Selected File!");
    }
  };

  const generateDepartmentPDF = () => {
    if (csvData) {
      if (dateFrom && dateTo) {
        if (selectDepartment) {
          handleDepartmentPdf();
          // navigate("/pdf");
        } else {
          alert("No Specified Department!");
        }
      } else {
        alert("No specified date range [from,to]!");
      }
    } else {
      alert("No Selected File!");
    }
  };

  const generateDepartmentExcel = () => {
    if (csvData) {
      if (dateFrom && dateTo) {
        if (selectDepartment) {
          handleExcelData();
        } else {
          alert("No Specified Department!");
        }
      } else {
        alert("no specified date range [from,to]!");
      }
    } else {
      alert("No Selected File!");
    }
  };

  const generateEmptyDepartmentReport = () => {
    if (csvData) {
      if (dateFrom && dateTo) {
        if (emptyDepartment) {
          if (results === "PDF") {
            handlePdfDataEmpty();
          } else if (results === "Excel") {
            handleExcelDataEmpty();
          }
        } else {
          alert("there is no tickets without department");
        }
      } else {
        alert("no specified date range [from,to]!");
      }
    } else {
      alert("No Selected File!");
    }
  };
  // const resetAllInterface = () => {
  //     resetAll()
  // }
  const editHandler = () => {
    navigate("/edit-table");
  };
  const uploadFileHandler = async (event) => {
    console.log(event.target.files[0]);
    await axios
      .post(
        "/api/add-file",
        { file: event.target.files[0] },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,POST,DELETE,PUT,OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => console.log(response))
      .error((error) => console.log(error));
  };
  const excelNavigateHandler = async () => {
    navigate("/editexcel");
  };

  // console.log("data ", csvData);
  return (
    <div className="interface__container">
      <div className="logo__div">
        <img
          src={require("../../assets/images/logo 2.png")}
          alt="3f Incs."
          className="logo"
        />
      </div>
      <div className="interface__buttons">
        <div className="interface__buttons__animation">
          {/* <div className="noerrors__msg">
                    <p className="noerrors__msg__p">All tickets have departments</p>
                </div> */}
          {show ? (
            emptyFlag ? (
              <div className="nodepartment__msg">
                <p className="nodepartment__msg__p">
                  There is some tickets without departments
                </p>
              </div>
            ) : (
              <div className="noerrors__msg">
                <p className="noerrors__msg__p">All tickets have departments</p>
              </div>
            )
          ) : (
            ""
          )}
          {emptyFlag && (
            <div>
              <div className="nodeps__holder">
                {/* <select className="select" value={results} onChange={(event) => setResults(event.target.value)}>
                                <option value={"PDF"}>PDF</option>
                                <option value={"Excel"}>Excel</option>
                            </select> */}
              </div>
              <div class="test2">
                <div class="SelectBox2">
                  <select
                    value={results}
                    onChange={(event) => setResults(event.target.value)}
                  >
                    <option value={"PDF"}>PDF</option>
                    <option value={"Excel"}>Excel</option>
                  </select>
                  <div class="chevron">
                    <div></div>
                  </div>
                </div>
              </div>
              <div className="select_button_div">
                <button
                  className="select_button"
                  onClick={generateEmptyDepartmentReport}
                >{`generate ${results} for empty department tickets`}</button>
              </div>
            </div>
          )}

          <div className="datepicker__div">
            <div className="datepicker__div__first">
              <label htmlFor="start" className="label-date">
                {" "}
                From :{" "}
              </label>
              <input
                type="date"
                id="start"
                name="trip-start"
                min="2022-08-01 "
                max="2024-12-31 "
                onChange={handleFrom}
              ></input>
              <label htmlFor="start" className="label-date">
                {" "}
                To :{" "}
              </label>
              <input
                type="date"
                id="start"
                name="trip-start"
                min="2022-08-01 "
                max="2024-12-31 "
                onChange={handleTo}
              ></input>
            </div>
          </div>
          {/* <div className="interface__department"><label htmlFor="department">department name : </label><input type="text" onChange={handleDepartment}></input></div> */}
          {departemntsNames.length !== 0 ? (
            <div class="test interface__department">
              <div class="SelectBox">
                <label htmlFor="department">department name : </label>
                <select onChange={(event) => handleDepartment(event)}>
                  <option value={"no department"} selected>
                    select department
                  </option>
                  {departemntsNames.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
                </select>
                <div class="chevron">
                  <div></div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <button className="interface__buttons__first">
            <input
              type="file"
              name="file"
              onChange={(event) => {
                setShow(true);
                changeHandler(event);

                uploadFileHandler(event);
              }}
              accept=".csv"
              className="interface__input"
            />
          </button>
          <button
            className="interface__reset__btn"
            onClick={excelNavigateHandler}
          >
            Edit Data In Excel
          </button>
          {/* <div>
            <button className="interface__edit__btn" onClick={editHandler}>
              Edit Data
            </button>
          </div> */}
        </div>
        <div className="interface__buttons__div">
          <button
            className="interface__buttons__second second"
            onClick={generateAllPDF}
          >
            Generate all departments to PDF File
          </button>
          <button
            className="interface__buttons__third second"
            onClick={generateAllExcel}
          >
            Generate all departments to Excel File
          </button>

          <div className="triangle"></div>
          <hr className="triangle__hr" />
          <div className="triangle__holder">
            <button
              className="interface__buttons__fourth  third"
              onClick={generateDepartmentPDF}
            >
              Generate department to PDF File
            </button>
            <button
              className="interface__buttons__fifth  third"
              onClick={generateDepartmentExcel}
            >
              Generate department to Excel File
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
