import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { StateProvider } from "./context/stateContext";

import { Home } from "./pages/home";
import { Login } from "./components/login/login";

import { ProtectedRoutes } from "./components/protectedRoutes/protectedRoutes";
import { Excel } from "./pages/excel";
import { History } from "./pages/history";

function App() {
  return (
    <StateProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route element={<ProtectedRoutes></ProtectedRoutes>}>
            <Route path="/interface" element={<Home />} />
            <Route path="/editexcel" element={<Excel />} />
            <Route path="/history" element={<History />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </StateProvider>
  );
}

export default App;
