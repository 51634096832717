import { ExcelButtons } from "../components/excelButtons/excelButtons";
import { SpreadSheet } from "../components/spreadSheet/spreadSheet";
import "./excel.css";
export const Excel = () => {
  return (
    <div className="excel__div">
      <ExcelButtons />

      <SpreadSheet />
    </div>
  );
};
