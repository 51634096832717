import { DeleteModal } from "../deleteModal/deleteModal";
import "./historyTable.css";
export const HistoryTable = ({ data, setData }) => {
  console.log(data);

  return (
    <div className="historytable">
      <table>
        <caption style={{ marginBottom: "10px" }}>
          All the files you uploaded
        </caption>

        <tbody>
          <tr style={{ textAlign: "center" }}>
            <td>Number</td>
            <td>File Name</td>
            <td>Uploaded Date</td>
            <td>Download</td>
            <td>Delete</td>
          </tr>
          {data &&
            data.map((file, index) => (
              <tr key={file._id}>
                <td>{index + 1}</td>
                <td>{file.file_name}</td>
                <td> {new Date(file.createdAt).toLocaleDateString("en-gb")}</td>
                <td>
                  <a className="historytable__a" href={file.file_path}>
                    <button className="historytable__btn">Download file</button>
                  </a>
                </td>
                <td>
                  {" "}
                  <DeleteModal id={file._id} setData={setData} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
