import { useEffect, useState } from "react";
import { Header } from "../components/header/header";
import { HistoryTable } from "../components/historyTable/historyTable";
import useFetch from "../customHooks/useFetch";

export const History = () => {
  const [data, setData] = useState();
  const { data: allFiles, loading, error } = useFetch("/api/allFiles");
  useEffect(() => {
    setData(allFiles);
  }, [allFiles]);
  return (
    <div>
      <Header place="home" />
      <HistoryTable data={data} setData={setData} />
    </div>
  );
};
