import "./spreadSheet.css";
import React from "react";
import { useContext } from "react";
import StateContext from "../../context/stateContext";
import Handsontable from "handsontable";
import "handsontable/dist/handsontable.full.min.css";
import { HotTable } from "@handsontable/react";

export const SpreadSheet = () => {
  // return <div>spreed sheet</div>;
  const { dataExcel, setEditData } = useContext(StateContext);
  const onSave = (data) => {
    console.log("here the data has been saved", data);
    data?.forEach((element) => {
      console.log("get data for element", dataExcel[element[0]][element[1]]);
    });
    setEditData(true);
    // Save the data to the same object.
    // setDataExcel(dataExcel);
    // handleDataModified();
  };

  // const [data, setData] = useState(dataExcel);
  // const handleChange = (changes) => {
  //   setEditData(true);
  //   setData(changes);
  //   console.log("changes", changes);
  //   setDataExcel(changes);
  //   handleDataModified();
  // };

  // const columns = [
  //   {
  //     ...keyColumn("Agent Name", textColumn),
  //     title: "Agent Name",
  //     cell: (cell, row) => cell["Agent Name"],
  //   },
  //   {
  //     ...keyColumn("Billable", textColumn),
  //     title: "Billable",
  //     cell: (cell, row) => cell["Billable"],
  //   },
  //   // {
  //   //   ...keyColumn("Executed Date", textColumn),
  //   //   title: "Executed Date",
  //   // },
  //   // {
  //   //   ...keyColumn("Last Updated Date", textColumn),
  //   //   title: "Last Updated Date",
  //   // },
  //   {
  //     ...keyColumn("Note", textColumn),
  //     title: "Note",
  //     cell: (cell, row) => cell["Note"],
  //     grow: 6,
  //   },
  //   {
  //     ...keyColumn("Ticket Company Name", textColumn),
  //     title: "Ticket Company Name",
  //     cell: (cell, row) => cell["Ticket Company Name"],
  //   },
  //   {
  //     ...keyColumn("Ticket ID", textColumn),
  //     title: "Ticket ID",
  //     cell: (cell, row) => cell["Ticket ID"],
  //   },
  //   {
  //     ...keyColumn("Ticket Subject", textColumn),
  //     title: "Ticket Subject",
  //     cell: (cell, row) => cell["Ticket Subject"],
  //   },
  //   {
  //     ...keyColumn("Total Hours", textColumn),
  //     title: "Total Hours",
  //     cell: (cell, row) => cell["Total Hours"],
  //   },
  // ];

  // return (
  //   <DataSheetGrid value={data} onChange={handleChange} columns={columns} />
  // );

  return (
    <HotTable
      data={dataExcel}
      height={850}
      colWidths={[200, 250, 90, 90, 90, 90, 420, 100, 50]}
      colHeaders={[
        "Ticket Company Name",
        "Ticket Subject",
        "Ticket ID",
        "Total Hours",
        "Last Updated Date",
        "Executed Date",
        "Note",
        "Agent Name",
        "Billable",
      ]}
      // rowHeights={100}
      // columnHeaderHeight={50}

      contextMenu={true}
      rowHeaders={true}
      afterChange={onSave}
      licenseKey="non-commercial-and-evaluation"
      // hideColumns={[6, 7]}
    >
      {/* <HotColumn data={1} />
      <HotColumn data={2} />
      <HotColumn data={3} />
      <HotColumn data={4} />
      <HotColumn data={5} />
      <HotColumn data={6} />
      <HotColumn data={7} />
      <HotColumn data={8} />
      <HotColumn data={9} /> */}
    </HotTable>
  );
};
