import { createContext, useState } from "react";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "../firebase";
import Papa from "papaparse";
import moment from "moment";
import * as XLSX from "xlsx";
import "moment-duration-format";
import axios from "axios";
import fileDownload from "js-file-download";
const StateContext = createContext();

export function StateProvider({ children }) {
  const [csvData, setCsvData] = useState(null);
  const [dataExcel, setDataExcel] = useState(null);
  const [totalHours, settotalHours] = useState(null);
  const [totalHoursUnbillable, settotalHoursUnbillable] = useState(null);
  const [totalHoursBillable, settotalHoursBillable] = useState(null);
  const [dateFrom, setdateFrom] = useState(null);
  const [dateTo, setdateTo] = useState(null);
  const [sumOfCompany, setsumOfCompany] = useState(null);
  const [excelData, setexcelData] = useState(null);
  const [selectDepartment, setselectDepartment] = useState(null);
  const [selectDepartmentArray, setselectDepartmentArray] = useState(null);
  const [totalHoursDepartment, settotalHoursDepartment] = useState();
  const [hoursBill, sethoursBill] = useState();
  const [hoursUnbill, sethoursUnbill] = useState();
  const [pdfData, setpdfData] = useState();
  const [emptyDepartment, setemptyDepartment] = useState();
  const [emptyDepartmentHours, setemptyDepartmentHours] = useState();
  const [emptyDepartmentbill, setemptyDepartmentbill] = useState();
  const [emptyDepartmentUnbill, setemptyDepartmentUnbill] = useState();
  const [emptyFlag, setemptyFlag] = useState(false);
  const [departemntsNames, setdepartemntsNames] = useState([]);
  const [show, setShow] = useState(false);
  const [token, setToken] = useState(true);
  const [editData, setEditData] = useState(false);
  // reset all function
  const resetAll = () => {
    setCsvData(null);
    settotalHours(null);
    settotalHoursUnbillable(null);
    settotalHoursBillable(null);
    setdateFrom("");
    setdateTo("");
    setsumOfCompany(null);
    setexcelData(null);
    setselectDepartment(null);
    setselectDepartmentArray(null);
    settotalHoursDepartment(null);
    sethoursBill(null);
    sethoursUnbill(null);
    setpdfData(null);
    setemptyDepartment(null);
    setemptyDepartmentHours(null);
    setemptyDepartmentbill(null);
    setemptyDepartmentUnbill(null);
    setemptyFlag(false);
    setShow(false);
    setdepartemntsNames([]);
  };

  // the login function of firebase
  const login = (email, password) => {
    setToken(true);
    return signInWithEmailAndPassword(auth, email, password);
  };
  const logout = () => {
    setToken(false);
    return signOut(auth);
  };

  const splitTime = (time) =>
    time.match(
      /((?<days>\d{1,2})(?:d))?\s?((?<hours>\d{1,2})(?:h))?\s?((?<minutes>\d{1,2})(?:m))?\s?((?<seconds>\d{1,2})(?:s))?/
    ).groups;
  const changeHandler = (event) => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const parsedData = results.data;
        setDataExcel(parsedData);
        console.log("here is data loaded ", results.data);
        //parsing time to object of hours, minutes and seconds
        results.data.forEach((item) => {
          item["Total Hours"] = splitTime(item["Total Hours"]);
          // console.log("item found ", item);
        });

        // console.log("try to get data", results.data);

        var sumTotal = moment.duration({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
        var sumBillable = moment.duration({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
        var sumUnbillable = moment.duration({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });

        //get the sum total duration of all tracked times
        results.data.forEach((item) => {
          sumTotal = sumTotal.add(
            moment.duration({
              days: parseInt(item["Total Hours"].days)
                ? parseInt(item["Total Hours"].days)
                : 0,
              hours: parseInt(item["Total Hours"].hours)
                ? parseInt(item["Total Hours"].hours)
                : 0,
              minutes: parseInt(item["Total Hours"].minutes)
                ? parseInt(item["Total Hours"].minutes)
                : 0,
              seconds: parseInt(item["Total Hours"].seconds)
                ? parseInt(item["Total Hours"].seconds)
                : 0,
            })
          );
          // console.log('sum loop', sum.format('hh:mm:ss'));
        });

        // get the sum total of billable tracked hours and unbillable tracked hours
        results.data.forEach((item) => {
          if (item["Billable"] === "TRUE" || item["Billable"] === "true") {
            // console.log('bill',item);

            sumBillable = sumBillable.add(
              moment.duration({
                days: parseInt(item["Total Hours"].days)
                  ? parseInt(item["Total Hours"].days)
                  : 0,
                hours: parseInt(item["Total Hours"].hours)
                  ? parseInt(item["Total Hours"].hours)
                  : 0,
                minutes: parseInt(item["Total Hours"].minutes)
                  ? parseInt(item["Total Hours"].minutes)
                  : 0,
                seconds: parseInt(item["Total Hours"].seconds)
                  ? parseInt(item["Total Hours"].seconds)
                  : 0,
              })
            );
          } else if (
            item["Billable"] === "FALSE" ||
            item["Billable"] === "false"
          ) {
            // console.log('unbillable',item);

            sumUnbillable = sumUnbillable.add(
              moment.duration({
                days: parseInt(item["Total Hours"].days)
                  ? parseInt(item["Total Hours"].days)
                  : 0,
                hours: parseInt(item["Total Hours"].hours)
                  ? parseInt(item["Total Hours"].hours)
                  : 0,
                minutes: parseInt(item["Total Hours"].minutes)
                  ? parseInt(item["Total Hours"].minutes)
                  : 0,
                seconds: parseInt(item["Total Hours"].seconds)
                  ? parseInt(item["Total Hours"].seconds)
                  : 0,
              })
            );
          }
        });

        //split array to grouped arrays by company
        const grouped = results.data.reduce((acc, i) => {
          if (!acc[i["Ticket Company Name"]])
            acc[i["Ticket Company Name"]] = [];
          acc[i["Ticket Company Name"]].push(i);
          return acc;
        }, {});

        const final = Object.values(
          Object.entries(grouped).reduce((acc, [key, values]) => {
            if (values.length > 1) {
              acc[key] = values;
            } else {
              if (!acc.others) acc.others = [];
              acc.others.push(values[0]);
            }
            return acc;
          }, {})
        );

        // get total hours of every company
        const testSum = [];
        let sumOf = moment.duration({ hours: 0, minutes: 0, seconds: 0 });
        final.forEach((item) => {
          item.forEach((arr) => {
            sumOf = sumOf.add(
              moment.duration({
                days: parseInt(arr["Total Hours"].days)
                  ? parseInt(arr["Total Hours"].days)
                  : 0,
                hours: parseInt(arr["Total Hours"].hours)
                  ? parseInt(arr["Total Hours"].hours)
                  : 0,
                minutes: parseInt(arr["Total Hours"].minutes)
                  ? parseInt(arr["Total Hours"].minutes)
                  : 0,
                seconds: parseInt(arr["Total Hours"].seconds)
                  ? parseInt(arr["Total Hours"].seconds)
                  : 0,
              })
            );
          });
          testSum.push(sumOf);
          sumOf = moment.duration({ hours: 0, minutes: 0, seconds: 0 });

          // console.log('sum company',testSum);
        });
        // console.log('test again', results.data);
        // var dataPdf = final
        final.forEach((item) => {
          item.forEach((arr) => {
            // console.log('array',arr);
            arr["Total Hours"] = moment.duration({
              days: parseInt(arr["Total Hours"].days)
                ? parseInt(arr["Total Hours"].days)
                : 0,
              hours: parseInt(arr["Total Hours"].hours)
                ? parseInt(arr["Total Hours"].hours)
                : 0,
              minutes: parseInt(arr["Total Hours"].minutes)
                ? parseInt(arr["Total Hours"].minutes)
                : 0,
              seconds: parseInt(arr["Total Hours"].seconds)
                ? parseInt(arr["Total Hours"].seconds)
                : 0,
            });
            // console.log('test loop',moment.duration(arr['Total Hours'].asHours(), "hours").format("hh:mm", {
            //     trim: false
            // }));
            arr["Total Hours"] = moment
              .duration(arr["Total Hours"].asHours(), "hours")
              .format("hh:mm", {
                trim: false,
              });
          });
        });

        var getNames = [];
        final.forEach((item) => {
          item.forEach((name) => {
            if (
              !getNames.find(
                (element) => element === name["Ticket Company Name"]
              ) &&
              name["Ticket Company Name"] !== ""
            ) {
              getNames.push(name["Ticket Company Name"]);
            }
          });
        });
        console.log("getnames", getNames);

        // console.log('test hours',moment.duration(sumTotal._data).format('hh:mm',{trim:false}));
        // console.log('test hours',moment.duration(sumUnbillable._data).format('hh:mm',{trim:false}));
        // console.log('test hours',moment.duration(sumBillable._data).format('hh:mm',{trim:false}));
        // console.log('test hours',sumTotal._data);

        // console.log('test final array',final);

        // let flagTest = false;
        // let sumEmpty
        const empty = final.filter((item, idx) => {
          if (item[0]["Ticket Company Name"] === "") {
            // flagTest=true
            setemptyFlag(true);
            // sumEmpty=testSum[idx]
            setemptyDepartmentHours(
              moment
                .duration(testSum[idx]._data)
                .format("hh:mm", { trim: false })
            );
            return item[0]["Ticket Company Name"] === "";
          }
          return null;
        });

        var sumBillableEmpty = moment.duration({
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
        var sumUnbillableEmpty = moment.duration({
          hours: 0,
          minutes: 0,
          seconds: 0,
        });

        // empty.forEach((item)=>{
        //     console.log('test lopp',item[0] );
        //     if(item[0]['Billable'] === "TRUE" || item[0]['Billable'] === "true"){
        //         console.log('billempty',item);
        //         // console.log('test lopp');
        //         sumBillableEmpty = sumBillableEmpty.add(moment.duration(item[0]['Total Hours']))
        //     }else if(item[0]['Billable'] === "FALSE" || item[0]['Billable'] === "false"){
        //         // console.log('unbillable',item);
        //         sumUnbillableEmpty = sumUnbillableEmpty.add(moment.duration(item[0]['Total Hours']))
        //     }
        // })

        empty.forEach((item) => {
          item.forEach((row) => {
            if (row["Billable"] === "TRUE" || row["Billable"] === "true") {
              // console.log('billempty',item);
              // console.log('test lopp');
              sumBillableEmpty = sumBillableEmpty.add(
                moment.duration(row["Total Hours"])
              );
            } else if (
              row["Billable"] === "FALSE" ||
              row["Billable"] === "false"
            ) {
              // console.log('unbillable',item);
              sumUnbillableEmpty = sumUnbillableEmpty.add(
                moment.duration(row["Total Hours"])
              );
            }
          });
        });

        // console.log('empty1',empty);
        // console.log('empty2',flagTest);
        // console.log('empty3',sumEmpty);
        // console.log('empty3 bill',sumBillableEmpty);
        // console.log('empty3 unbill',sumUnbillableEmpty);
        // set state with the data
        console.log("test sum of company", testSum);
        setCsvData(final);
        settotalHours(
          moment.duration(sumTotal._data).format("hh:mm", { trim: false })
        );
        // console.log(
        //   "total hours set",
        //   moment.duration(sumTotal._data).format("hh:mm", { trim: false })
        // );
        settotalHoursUnbillable(
          moment.duration(sumUnbillable._data).format("hh:mm", { trim: false })
        );
        settotalHoursBillable(
          moment.duration(sumBillable._data).format("hh:mm", { trim: false })
        );
        setsumOfCompany(testSum);
        setexcelData(results.data);
        setpdfData(final);
        setemptyDepartment(empty);
        setemptyDepartmentbill(
          moment
            .duration(sumBillableEmpty._data)
            .format("hh:mm", { trim: false })
        );
        setemptyDepartmentUnbill(
          moment
            .duration(sumUnbillableEmpty._data)
            .format("hh:mm", { trim: false })
        );
        setdepartemntsNames(getNames);
      },
    });
  };

  const handleDataModified = () => {
    if (editData === true) {
      // setDataExcel(dataExcel);
      // dataExcel.forEach((item) => {
      //   item["Total Hours"] = moment.duration(item["Total Hours"]);
      //   console.log("test change time", item["Total Hours"]);
      // });

      // const newModifiedData = [...dataExcel];
      const newCopy = dataExcel.map((item) => ({ ...item }));
      console.log(newCopy);
      // const newData = newCopy.map((item) => {
      //   return (item["Total Hours"] = moment.duration(item["Total Hours"]));
      // });
      const totalDuration = moment.duration();
      newCopy.forEach((item) => {
        item["Total Hours"] = moment.duration(item["Total Hours"]);
        // console.log("test change time", item["Total Hours"]);
        totalDuration.add(item["Total Hours"]);
      });

      const formattedTotal = totalDuration.format("hh:mm", { trim: false });
      console.log("formattedTotal", formattedTotal);
      settotalHours(formattedTotal);

      const totalDurationBillable = moment.duration();
      const totalDurationUnBillable = moment.duration();
      newCopy.forEach((item) => {
        if (item["Billable"] === "TRUE" || item["Billable"] === "true") {
          totalDurationBillable.add(item["Total Hours"]);
        } else {
          totalDurationUnBillable.add(item["Total Hours"]);
        }
      });

      settotalHoursUnbillable(
        totalDurationUnBillable.format("hh:mm", { trim: false })
      );
      settotalHoursBillable(
        totalDurationBillable.format("hh:mm", { trim: false })
      );

      const grouped = newCopy.reduce((acc, i) => {
        if (!acc[i["Ticket Company Name"]]) acc[i["Ticket Company Name"]] = [];
        acc[i["Ticket Company Name"]].push(i);
        return acc;
      }, {});

      const final = Object.values(
        Object.entries(grouped).reduce((acc, [key, values]) => {
          if (values.length > 1) {
            acc[key] = values;
          } else {
            if (!acc.others) acc.others = [];
            acc.others.push(values[0]);
          }
          return acc;
        }, {})
      );

      final.forEach((item) => {
        item.forEach((arr) => {
          // console.log('array',arr);
          // console.log('test loop',moment.duration(arr['Total Hours'].asHours(), "hours").format("hh:mm", {
          //     trim: false
          // }));
          arr["Total Hours"] = moment
            .duration(arr["Total Hours"].asHours(), "hours")
            .format("hh:mm", {
              trim: false,
            });
        });
      });
      setCsvData(final);
      setpdfData(final);

      const testSumAllCompanies = [];
      let sumOf = moment.duration();
      final.forEach((item) => {
        item.forEach((arr) => {
          sumOf.add(arr["Total Hours"]);
        });
        testSumAllCompanies.push(sumOf);
        sumOf = moment.duration();
      });

      setsumOfCompany(testSumAllCompanies);

      const empty = final.filter((item, idx) => {
        if (item[0]["Ticket Company Name"] === "") {
          // flagTest=true
          setemptyFlag(true);
          // sumEmpty=testSum[idx]
          setemptyDepartmentHours(
            moment
              .duration(testSumAllCompanies[idx]._data)
              .format("hh:mm", { trim: false })
          );
          return item[0]["Ticket Company Name"] === "";
        }
        return null;
      });

      setemptyDepartment(empty);

      let sumBillableEmpty = moment.duration();
      let sumUnbillableEmpty = moment.duration();

      empty.forEach((item) => {
        item.forEach((row) => {
          if (row["Billable"] === "TRUE" || row["Billable"] === "true") {
            // console.log('billempty',item);
            // console.log('test lopp');
            sumBillableEmpty = sumBillableEmpty.add(
              moment.duration(row["Total Hours"])
            );
          } else if (
            row["Billable"] === "FALSE" ||
            row["Billable"] === "false"
          ) {
            // console.log('unbillable',item);
            sumUnbillableEmpty = sumUnbillableEmpty.add(
              moment.duration(row["Total Hours"])
            );
          }
        });
      });

      setemptyDepartmentbill(
        moment.duration(sumBillableEmpty._data).format("hh:mm", { trim: false })
      );
      setemptyDepartmentUnbill(
        moment
          .duration(sumUnbillableEmpty._data)
          .format("hh:mm", { trim: false })
      );

      // console.log("final result", final);
      // console.log("final result", empty);
      // console.log(
      //   "final result",
      //   moment.duration(sumBillableEmpty._data).format("hh:mm", { trim: false })
      // );
      // console.log(
      //   "final result",
      //   moment
      //     .duration(sumUnbillableEmpty._data)
      //     .format("hh:mm", { trim: false })
      // );

      // console.log("excel data here in func", dataExcel);
      // console.log("excel data here in func66", newCopy);
    }
  };

  // handle input date from
  const handleFrom = (event) => {
    setdateFrom(event.target.value);
  };
  // handle input date to
  const handleTo = (event) => {
    setdateTo(event.target.value);
  };
  const handleDepartment = (event) => {
    var selected = [];
    var selectedHours = [];
    console.log("test name", event.target.value);

    pdfData.forEach((element, index) => {
      if (element[0]["Ticket Company Name"] === event.target.value) {
        // console.log('true',element);
        selected = element;
        console.log("selected", selected);
        setselectDepartmentArray(element);
        // totalHoursDepartment = sumOfCompany[index]
        console.log("test sum of company", sumOfCompany);
        selectedHours = sumOfCompany[index];
        settotalHoursDepartment(
          moment.duration(selectedHours._data).format("hh:mm", { trim: false })
        );
        // console.log('company',selected);
        // console.log('company time',totalHoursDepartment);
      }
    });

    // console.log('department data',selected);

    var sumBillable = moment.duration({ hours: 0, minutes: 0, seconds: 0 });
    var sumUnbillable = moment.duration({ hours: 0, minutes: 0, seconds: 0 });

    selected.forEach((item) => {
      if (item["Billable"] === "TRUE" || item["Billable"] === "true") {
        // console.log('bill',item);
        sumBillable = sumBillable.add(moment.duration(item["Total Hours"]));
      } else if (item["Billable"] === "FALSE" || item["Billable"] === "false") {
        // console.log('unbillable',item);
        sumUnbillable = sumUnbillable.add(moment.duration(item["Total Hours"]));
      }
    });

    // sumBillable = selectedHours.asHours()-sumUnbillable.asHours()

    // console.log('departbill',moment.duration(sumBillable._data).format('hh:mm',{trim:false}));
    // console.log('departunbill', moment.duration(sumUnbillable._data).format('hh:mm',{trim:false}));
    // console.log('total', moment.duration(selectedHours._data).format('hh:mm',{trim:false}));

    sethoursBill(
      moment.duration(sumBillable._data).format("hh:mm", { trim: false })
    );
    sethoursUnbill(
      moment.duration(sumUnbillable._data).format("hh:mm", { trim: false })
    );
    setselectDepartment(event.target.value);

    // axios
    //   .post(
    //     "http://localhost:3004/generate-pdf",
    //     {
    //       department: event.target.value,
    //       tracked_hours: moment
    //         .duration(selectedHours._data)
    //         .format("hh:mm", { trim: false }),
    //       billable_hours: moment
    //         .duration(sumBillable._data)
    //         .format("hh:mm", { trim: false }),
    //       non_billable_hours: moment
    //         .duration(sumUnbillable._data)
    //         .format("hh:mm", { trim: false }),
    //       from: dateFrom,
    //       to: dateTo,
    //       tickets: selected,
    //     },
    //     { responseType: "blob" }
    //   )
    //   .then(function (response) {
    //     fileDownload(
    //       response.data,
    //       `timeSheet_from_${dateFrom}_to_${dateTo}_dep_${event.target.value}.pdf`
    //     );
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  };

  const handleDepartmentPdf = () => {
    axios
      .post(
        "/api/generate-pdf",
        {
          department: selectDepartment,
          tracked_hours: totalHoursDepartment,
          billable_hours: hoursBill,
          non_billable_hours: hoursUnbill,
          from: dateFrom,
          to: dateTo,
          tickets: selectDepartmentArray,
        },
        { responseType: "blob" }
      )
      .then(function (response) {
        fileDownload(
          response.data,
          `timeSheet_from_${dateFrom}_to_${dateTo}_dep_${selectDepartment}.pdf`
        );
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // handle generating excel file
  const handleExcelData = () => {
    if (selectDepartment) {
      var selected = [];
      csvData.forEach((element, index) => {
        if (element[0]["Ticket Company Name"] === selectDepartment) {
          // console.log('true',element);
          selected = element;
        }
      });

      // selected.forEach((item)=>{
      //     item['Total Hours'] = moment.duration({
      //         days: parseInt(item['Total Hours'].days) ? parseInt(item['Total Hours'].days) : 0,
      //         hours: parseInt(item['Total Hours'].hours) ?parseInt(item['Total Hours'].hours) : 0,
      //         minutes: parseInt(item['Total Hours'].minutes) ?parseInt(item['Total Hours'].minutes) :0,
      //         seconds: parseInt(item['Total Hours'].seconds) ?parseInt(item['Total Hours'].seconds)  : 0,
      //     })
      //     // console.log('test loop',moment.duration(item['Total Hours'].asHours(), "hours").format("hh:mm:ss"));
      //     item['Total Hours'] = moment.duration(item['Total Hours'].asHours(), "hours").format("hh:mm",{trim:false})
      // })

      var wb1 = XLSX.utils.book_new(),
        ws1 = XLSX.utils.json_to_sheet(selected);

      XLSX.utils.book_append_sheet(wb1, ws1, "sheet-1");
      let fileName = "";
      if (dateFrom === null && dateTo === null) {
        fileName = `Report for ${new Date().getFullYear()}_${selectDepartment}.xlsx`;
      } else {
        fileName = `timeSheet_from_${dateFrom}_to_${dateTo}_${selectDepartment}.xlsx`;
      }
      XLSX.writeFile(wb1, fileName);
    } else {
      // excelData.forEach((item)=>{
      //     // item['Total Hours'] = `${item['Total Hours'].hours ? item['Total Hours'].hours : 0}:${item['Total Hours'].minutes ? item['Total Hours'].minutes : 0}:${item['Total Hours'].seconds ? item['Total Hours'].seconds : 0}`
      //     console.log('before',item['Total Hours']);
      //     item['Total Hours'] = moment.duration({
      //         days: parseInt(item['Total Hours'].days) ? parseInt(item['Total Hours'].days) : 0,
      //         hours: parseInt(item['Total Hours'].hours) ?parseInt(item['Total Hours'].hours) : 0,
      //         minutes: parseInt(item['Total Hours'].minutes) ?parseInt(item['Total Hours'].minutes) :0,
      //         seconds: parseInt(item['Total Hours'].seconds) ?parseInt(item['Total Hours'].seconds)  : 0,
      //     })
      //     console.log('test loop',moment.duration(item['Total Hours'].asHours(), "hours").format("hh:mm:ss"));
      //     item['Total Hours'] = moment.duration(item['Total Hours'].asHours(), "hours").format("hh:mm:ss")
      // })

      // console.log('excel',excelData);
      var wb2 = XLSX.utils.book_new(),
        ws2 = XLSX.utils.json_to_sheet(excelData);

      XLSX.utils.book_append_sheet(wb2, ws2, "sheet-1");
      let fileName = "";
      if (dateFrom === null && dateTo === null) {
        fileName = `Report for ${new Date().getFullYear()}.xlsx`;
      } else {
        fileName = `timeSheet_from_${dateFrom}_to_${dateTo}.xlsx`;
      }
      XLSX.writeFile(wb2, fileName);
    }
  };

  const handleAlldataPdf = () => {
    const grouped = Object.values(
      pdfData.reduce((acc, item) => {
        // Append the item to the array for each country
        acc[item["Ticket Company Name"]] = [
          ...(acc[item["Ticket Company Name"]] || []),
          item,
        ];
        return acc;
      }, {})
    );

    console.log("test group", grouped);

    for (var i = 0; i < grouped.length; i++) {
      for (var j = 0; j < grouped[i].length; j++) {
        console.log("test!!!!!!", grouped[i][j][0]["Ticket Company Name"]);

        let totalHoursDepartment = sumOfCompany[j];

        let sumBillable = moment.duration({ hours: 0, minutes: 0, seconds: 0 });
        let sumUnbillable = moment.duration({
          hours: 0,
          minutes: 0,
          seconds: 0,
        });

        grouped[i][j].forEach((item, i) => {
          if (item["Billable"] === "TRUE" || item["Billable"] === "true") {
            // console.log('bill',item);
            sumBillable = sumBillable.add(moment.duration(item["Total Hours"]));
          } else if (
            item["Billable"] === "FALSE" ||
            item["Billable"] === "false"
          ) {
            // console.log('unbillable',item);
            sumUnbillable = sumUnbillable.add(
              moment.duration(item["Total Hours"])
            );
          }
        });

        let dep_name = grouped[i][j][0]["Ticket Company Name"];

        axios
          .post(
            "/api/generate-pdf",
            {
              department: grouped[i][j][0]["Ticket Company Name"],
              tracked_hours: moment
                .duration(totalHoursDepartment._data)
                .format("hh:mm", { trim: false }),
              billable_hours: moment
                .duration(sumBillable._data)
                .format("hh:mm", { trim: false }),
              non_billable_hours: moment
                .duration(sumUnbillable._data)
                .format("hh:mm", { trim: false }),
              from: dateFrom,
              to: dateTo,
              tickets: grouped[i][j],
            },
            { responseType: "blob" }
          )
          .then(function (response) {
            fileDownload(
              response.data,
              `timeSheet_from_${dateFrom}_to_${dateTo}_${dep_name}_department.pdf`
            );
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }

    // axios
    //   .post(
    //     "http://localhost:3004/generate-pdf",
    //     {
    //       department: "All Departments",
    //       tracked_hours: totalHours,
    //       billable_hours: emptyDepartmentbill,
    //       non_billable_hours: emptyDepartmentUnbill,
    //       from: dateFrom,
    //       to: dateTo,
    //       tickets: pdfData,
    //     },
    //     { responseType: "blob" }
    //   )
    //   .then(function (response) {
    //     fileDownload(
    //       response.data,
    //       `timeSheet_from_${dateFrom}_to_${dateTo}_tickets_no_department.pdf`
    //     );
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  };

  const handleExcelDataEmpty = () => {
    let test = emptyDepartment.flat(1);
    //    console.log('flat',test);

    var wb3 = XLSX.utils.book_new(),
      ws3 = XLSX.utils.json_to_sheet(test);

    XLSX.utils.book_append_sheet(wb3, ws3, "sheet-1");
    let fileName = "";
    if (dateFrom === null && dateTo === null) {
      fileName = `Report for ${new Date().getFullYear()}_no_department.xlsx`;
    } else {
      fileName = `timeSheet_from_${dateFrom}_to_${dateTo}_no_department.xlsx`;
    }
    XLSX.writeFile(wb3, fileName);
  };

  const handlePdfDataEmpty = () => {
    axios
      .post(
        "/api/generate-pdf",
        {
          department: "tickets with no department",
          tracked_hours: emptyDepartmentHours,
          billable_hours: emptyDepartmentbill,
          non_billable_hours: emptyDepartmentUnbill,
          from: dateFrom,
          to: dateTo,
          tickets: emptyDepartment,
        },
        { responseType: "blob" }
      )
      .then(function (response) {
        fileDownload(
          response.data,
          `timeSheet_from_${dateFrom}_to_${dateTo}_tickets_no_department.pdf`
        );
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <StateContext.Provider
      value={{
        resetAll,
        show,
        setShow,
        csvData,
        setCsvData,
        changeHandler,
        totalHours,
        totalHoursUnbillable,
        totalHoursBillable,
        handleAlldataPdf,
        dateFrom,
        dateTo,
        handleFrom,
        handleTo,
        sumOfCompany,
        handleExcelData,
        excelData,
        handleDepartment,
        selectDepartment,
        selectDepartmentArray,
        hoursBill,
        hoursUnbill,
        totalHoursDepartment,
        handleDepartmentPdf,
        pdfData,
        emptyDepartment,
        emptyFlag,
        emptyDepartmentHours,
        emptyDepartmentbill,
        emptyDepartmentUnbill,
        departemntsNames,
        handleExcelDataEmpty,
        handlePdfDataEmpty,
        login,
        logout,
        token,
        setToken,
        dataExcel,
        setDataExcel,
        setEditData,
        handleDataModified,
      }}
    >
      {children}
    </StateContext.Provider>
  );
}

export default StateContext;
