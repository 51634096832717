import "./header.css";
import StateContext from "../../context/stateContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

export const Header = ({ place }) => {
  const navigate = useNavigate();
  const { logout } = useContext(StateContext);
  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      console.log("You are logged out");
    } catch (e) {
      console.log(e.message);
    }
  };
  const historyHandler = () => {
    if (place === "home") {
      navigate("/interface");
    } else {
      navigate("/history");
    }
  };

  return (
    <div className="header">
      <div className="header__text">Time Sheet Generator</div>
      <div className="header__logout">
        <button className="header__history__btn" onClick={historyHandler}>
          {place}
        </button>
        <button className="header__logout__btn" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};
