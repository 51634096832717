import { useNavigate } from "react-router-dom";
import "./excelButtons.css";
import { useContext, useState } from "react";
import StateContext from "../../context/stateContext";
export const ExcelButtons = () => {
  const { handleDataModified, setEditData } = useContext(StateContext);
  const navigate = useNavigate();
  const backHandler = () => {
    navigate("/interface");
  };
  const saveHandler = () => {
    handleDataModified();
    console.log("save is clicked");
    navigate("/interface");
  };
  return (
    <div className="excelbuttons__div">
      <button className="excel__btn" onClick={backHandler}>
        Back
      </button>
      <button className="excel__btn" onClick={saveHandler}>
        Save
      </button>
    </div>
  );
};
