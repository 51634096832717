import { Interface } from "../components/interface/interface";
import { Header } from "../components/header/header";

export const Home = () => {
  return (
    <div>
      <Header place="history" />
      <Interface />
    </div>
  );
};
