import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import "./deleteModal.css";
import axios from "axios";
import useFetch from "../../customHooks/useFetch";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const DeleteModal = ({ id, setData }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const deleteHandler = async () => {
    await axios
      .delete(`/api/deleteFile/${id}`)
      .then((response) => {
        axios.get("/api/allFiles").then((response) => {
          setData(response.data.history);
        });
      })
      .catch((error) => {
        console.log(error);
      });
    handleClose();
  };

  return (
    <div>
      <button className="historytable__delete" onClick={handleOpen}>
        Delete File
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete Confirmation
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure do you want to delete this file !!
          </Typography>
          <div
            style={{
              display: "flex",
              width: "100%",
              marginTop: "1rem",
              gap: "10rem",
            }}
          >
            <button className="deleteModal__cancel" onClick={handleClose}>
              Cancel
            </button>
            <button className="historytable__delete" onClick={deleteHandler}>
              Confirm
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
