import "./login.css"
import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import StateContext from "../../context/stateContext"

export const Login = () => {
    const { login } = useContext(StateContext)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError('')
        try {
            await login(email, password)
            navigate('/interface')
        } catch (e) {
            setError(e.message)
            console.log(error)
        }
    }


    return (<div className="login">
        <div className="login__div">
            <div className="login__text">Login</div>
            <p className='login__msg'>Welcome back</p>
            <form className="login__form" onSubmit={handleSubmit} >
                {/* <div className="login__email__div"><label className="login__label__email">Email : </label> */}
                <input className="login__email" onChange={(e) => { setEmail(e.target.value) }} type="email" placeholder="Email" required />
                {/* </div> */}
                {/* <div className="login__password__div"><label className="login__label__password" >Password : </label> */}
                <input className="login__password" onChange={(e) => { setPassword(e.target.value) }} type="password" placeholder="Password" required />
                {/* </div> */}

                <button className="login__submit">Login</button>
            </form>
        </div>
    </div>)
}